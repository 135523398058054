<template>
    <div class="site-struct full">


        <LoadingStruct is-dark :is-loading="loading">


            <div v-if="error" class="total-struct uk-padding">
                <div class="uk-grid" uk-grid>
                    <div class="uk-flex uk-flex-middle">
                        <div>
                            <LinkObject to="">
                                <img v-if="getInfos.logo"
                                     :src="apiUrl+'/logos/'+getInfos.logo" class="logo infos-name"/>
                                <span class="iname ">
                                        {{ getInfos.name }}
                                    </span>
                            </LinkObject>
                        </div>

                        <div class="uk-width-expand uk-invisible"/>
                        <div class="uk-flex uk-flex-middle uk-invisible">
                            <span class="gallery-name">
                                {{ getInfos.name }}
                            </span>
                        </div>
                        <div class="uk-invisible">
                            <Button is-large
                                    has-action

                                    mode="theme">

                                <Icon icon="cart"/>


                                <span class="uk-margin-small-left"/>
                                <Translate translation-key="cart"/>


                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <Card v-if="error" is-centered is-dark mode="default">

                <ErrorMessage :error="error"/>
                <div class="uk-margin-top"/>
                <Button is-large mode="primary" has-action :action="getGalleries" :is-loading="loading">
                    <Translate translation-key="retry"/>
                </Button>
            </Card>

            <div class="galleries-struct2" v-else-if="!galleries || galleries.length === 0">
                <div class="total-struct uk-padding">
                    <div class="uk-grid" uk-grid>
                        <div class="uk-flex uk-flex-middle">
                            <div>
                                <LinkObject to="">
                                    <img v-if="getInfos.logo"
                                         :src="apiUrl+'/logos/'+getInfos.logo" class="logo infos-name"/>
                                    <span class="iname ">
                                        {{ getInfos.name }}
                                    </span>
                                </LinkObject>
                            </div>

                            <div class="uk-width-expand uk-invisible"/>
                            <div class="uk-flex uk-flex-middle uk-invisible">
                            <span class="gallery-name">
                                {{ getInfos.name }}
                            </span>
                            </div>
                            <div class="uk-invisible">
                                <Button is-large
                                        has-action

                                        mode="theme">

                                    <Icon icon="cart"/>


                                    <span class="uk-margin-small-left"/>
                                    <Translate translation-key="cart"/>


                                </Button>
                            </div>
                        </div>
                    </div>

                </div>

                <Card is-centered is-dark mode="default">
                    <InfoBox>
                        <Translate translation-key="galleries.not.exist"/>
                    </InfoBox>
                </Card>
            </div>

            <div class="galleries-struct" v-else>


                <div class="total-struct uk-padding">
                    <div class="uk-grid" uk-grid>
                        <div class="uk-flex uk-flex-middle">
                            <div>
                                <LinkObject to="">
                                    <img v-if="getInfos.logo"
                                         :src="apiUrl+'/logos/'+getInfos.logo" class="logo infos-name"/>
                                    <span class="iname ">
                                        {{ getInfos.name }}
                                    </span>
                                </LinkObject>
                            </div>

                            <div class="uk-width-expand uk-invisible"/>
                            <div class="uk-flex uk-flex-middle uk-invisible">
                            <span class="gallery-name">
                                {{ getInfos.name }}
                            </span>
                            </div>
                            <div class="uk-invisible">
                                <Button is-large
                                        has-action

                                        mode="theme">

                                    <Icon icon="cart"/>


                                    <span class="uk-margin-small-left"/>
                                    <Translate translation-key="cart"/>


                                </Button>
                            </div>
                        </div>
                    </div>

                </div>


                <Card is-centered :title="$t('galleries')" is-dark mode="default">


                    <div uk-grid
                         class="uk-child-width-1-3@xl uk-child-width-1-2@l uk-child-width-1-2@m uk-child-width-1-1@s uk-child-width-1-1 uk-grid-match uk-flex uk-flex-center">
                        <div v-for="gallery in galleries" :key="gallery.id">
                            <GalleryItem :gallery="gallery"/>
                        </div>
                    </div>
                </Card>

            </div>


        </LoadingStruct>

    </div>
</template>

<script>

import LoadingStruct from "@/views/LoadingStruct";
import axios from "axios";
import Card from "@/components/generic/Card.vue";
import ErrorMessage from "@/components/generic/ErrorMessage.vue";
import Translate from "@/components/Translate.vue";
import GalleryItem from "@/components/GalleryItem.vue";
import Button from "@/components/generic/Button.vue";
import InfoBox from "@/components/generic/InfoBox.vue";
import LinkObject from "@/components/LinkObject.vue";
import Icon from "@/components/generic/Icon.vue";


export default {
    name: 'Galleries',
    components: {
        Icon,


        LinkObject,
        InfoBox,
        Button,
        GalleryItem,
        Translate,
        ErrorMessage,
        Card,
        LoadingStruct,
    },
    data() {
        return {
            loading: true,
            error: null,
            success: false,
            invalid: false,

            galleries: [],
        }
    },
    created() {
        this.getGalleries();
    },
    methods: {
        getGalleries() {
            this.loading = true;
            let http = axios.create();
            http.get(this.apiUrl + '/galleries'
            ).then(response => {
                this.galleries = response.data;

                this.error = null;
                this.loading = false;
                this.success = true;

            }).catch(e => {
                this.checkGenericError(e);
                this.error = e;
                this.loading = false;
                this.success = false;

            })
        }
    }
}

</script>

<style lang="scss" scoped>

.logo {
    height: 42px;
}


.iname {
    font-size: 16px;
    color: $site-background !important;
}

.gallery-name {
    font-size: 18px;
    font-weight: 500;
    color: $site-background !important;
}


.infos-name {
    margin-right: 5px;
}

.logo-struct {
    height: 42px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.empty-logo-struct {
    padding-top: 60px;
}

.galleries-struct {
    background-color: $site-color;
    min-height: 80vh;
}

.galleries-struct2 {
    background-color: $site-color;
}

.total-struct {
    padding-top: 15px !important;
    padding-bottom: 15px !important;

    position: sticky;
    top: 0;
    z-index: 1000;
    background: #F3F6F9 !important;
    // border-bottom: 1px solid $border-color !important;

    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(243, 246, 249, 0.1) !important;
}

</style>
