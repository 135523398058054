<template>
    <div>

        <LinkObject :to="'galleries/'+gallery.id">
            <div class="uk-card uk-card-default uk-card-hover cursor-pointer">

                <div class="uk-inline uk-transition-toggle uk-width-1-1">
                    <div class="uk-height-medium uk-background-cover"
                         :data-src="gallery.thumbnail ? apiUrl+'/galleries/'+gallery.id+'/thumbnail/'+gallery.thumbnail : require('../assets/default_thumbnail.jpg')"
                         uk-img="loading: eager">

                        <div class="uk-position-top-right uk-padding-small uk-text-center uk-overlay-hover">
                            <div>
                                <Label type="default-alt" icon="image" class="cursor-pointer uk-width-1-1">
                                    {{ gallery.stats.picturesTotal }}

                                    <Translate v-if=" gallery.stats.picturesTotal === 1" translation-key="picture"/>
                                    <Translate v-else translation-key="pictures"/>
                                </Label>
                            </div>

                            <div class="uk-margin-top"
                                 v-if="gallery.stats.folders && gallery.stats.folders>0"/>
                            <div v-if="gallery.stats.folders && gallery.stats.folders>0">
                                <Label type="default-alt" icon="folder" class="cursor-pointer uk-width-1-1">
                                    {{ gallery.stats.folders }}
                                    <Translate translation-key="folders"/>
                                </Label>
                            </div>

                            <div v-if="gallery.stats.sizeUsageInBytes" class="uk-margin-top"/>
                            <div v-if="gallery.stats.sizeUsageInBytes">
                                <Label type="default-alt" icon="database" class="cursor-pointer uk-width-1-1">
                                    {{
                                        ($options.filters.prettyBytes(gallery.stats.sizeUsageInBytes)).replace(".", ",")
                                    }}
                                </Label>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="uk-card uk-card-body uk-padding-xsmall control title-struct uk-text-center">
                    <div class="gallery-title">{{ gallery.name }}</div>
                </div>

            </div>
        </LinkObject>

    </div>
</template>


<script>

import Translate from "@/components/Translate.vue";
import Label from "@/components/Label.vue";
import LinkObject from "@/components/LinkObject.vue";

export default {
    name: 'GalleryItem',
    components: {LinkObject, Label, Translate},
    props: {
        gallery: {
            id: String,
            name: String,
            thumbnail: String,
            articleListID:
                [{
                    id: String,
                    paper: String,
                    width: Number,
                    height: Number,
                    price: Number,
                    graduatedPrices: [{
                        amount: Number,
                        price: Number,
                    }],
                    isFavorite: Boolean,
                    isHeightVariable: Boolean,
                    dpi: Number,
                    name: String,
                    isTerminal: Boolean,
                }],
            folders: [{
                name: String,
                thumbnail: String,
            }],
            stats: {
                sizeUsageInBytes: Number,
                pictures: Number,
                folders: Number,
            }
        },
    },
}

</script>

<style lang="scss" scoped>

.gallery-title {
    font-size: 24px;
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.generated-title {
    text-transform: uppercase;
    font-size: 64px;
    font-weight: 500;
}

.control {
    background-color: $theme-background;
}

</style>
