<template>
    <div
        class="uk-card uk-card-default uk-card-hover">

        <div v-if="item.isDir"
             :uk-tooltip="'title:'+item.name"
             class="uk-height-small uk-text-center folder-struct uk-padding-small uk-flex-middle uk-transition-toggle">
            <Icon icon="folder" :ratio="2.5"/>

            <div class="action-name uk-margin-small-top folder-name">
                {{ item.name }}
            </div>

            <div class="uk-transition-fade uk-overlay uk-overlay-primary uk-position-cover">
                <div class="uk-flex uk-flex-middle uk-flex-center uk-height-1-1">

                    <span v-on:click="actionOpenFolder()">
                        <Icon class="cursor-pointer"
                              icon="folder"
                              :uk-tooltip="'title:'+$t('folder.open')"
                              :ratio="1.5"/>
                    </span>

                    <span
                        class="uk-margin-small-left"
                        v-on:click="actionChangeThumbnail()">
                        <Icon class="cursor-pointer"
                              icon="image"
                              :uk-tooltip="'title:'+$t('thumbnail.change')"
                              :ratio="1.5"/>
                    </span>

                    <span class="uk-margin-small-left"
                          v-on:click="actionDeleteFolder()">
                        <Icon class="cursor-pointer"
                              icon="trash"
                              :uk-tooltip="'title:'+$t('delete')"
                              :ratio="1.5"/>
                    </span>

                </div>
            </div>
        </div>

        <div v-else>
            <div :uk-tooltip="'title:'+item.name"
                 class="uk-cover-container uk-transition-toggle uk-height-small">

                <div class="uk-flex uk-flex-center uk-flex-middle image-item">
                    <ImageEnhanced class="image-item-struct"
                                   :src="apiUrl+'/manage-galleries/'+galleryId+ (folder ? '/folders/'+folder.name : '')+'/file-thumbnails/'+item.name+'?token='+getToken"
                                   :alt="item.name"/>
                </div>
                <div class="uk-transition-fade uk-overlay uk-overlay-primary uk-position-cover">
                    <div class="uk-flex uk-flex-middle uk-flex-center uk-height-1-1">

                    <span v-on:click="actionDeleteFile()">
                        <Icon class="cursor-pointer"
                              icon="trash"
                              :uk-tooltip="'title:'+$t('delete')"
                              :ratio="1.5"/>
                    </span>
                    </div>
                </div>

            </div>

            <div :uk-tooltip="'title:'+item.name" class="name uk-text-center">
                {{ item.name }}
            </div>
        </div>
    </div>
</template>


<script>

import ImageEnhanced from "@/components/ImageEnhanced.vue";
import Icon from "@/components/generic/Icon.vue";
import axios from "axios";
import UIkit from "uikit";

export default {
    name: 'GalleryFile',
    components: {Icon, ImageEnhanced},
    props: {
        galleryId: String,
        item: {
            name: String,
            isDir: Boolean,
            path: String,
        },
        itemKey: Number,

        folder: Object,
    },
    methods: {
        actionDeleteFile() {
            let http = axios.create();

            let path = "";
            if (this.item.path !== "/") {
                path = this.item.path
            }

            this.createLoading = true;
            http.delete(this.apiUrl + '/manage-galleries/' + this.galleryId + '/files/' + path + this.item.name,
            ).then(() => {
                this.$emit('deleted', this.itemKey);

            }).catch(e => {
                this.checkGenericError(e);

                UIkit.notification({
                    message: this.$t('unexpected.error'),
                    status: 'error',
                    pos: 'top-center',
                    timeout: this.notificationTimeout,
                });

            });
        },
        actionDeleteFolder() {
            let http = axios.create();

            this.createLoading = true;
            http.delete(this.apiUrl + '/manage-galleries/' + this.galleryId + '/folders/' + this.item.name,
            ).then(() => {
                this.$emit('deleted', this.itemKey);

            }).catch(e => {
                this.checkGenericError(e);

                UIkit.notification({
                    message: this.$t('unexpected.error'),
                    status: 'error',
                    pos: 'top-center',
                    timeout: this.notificationTimeout,
                });

            });
        }
    }
}

</script>


<style lang="scss" scoped>

.image-item-struct {
    max-height: 150px;
    margin: auto;
}

.image-item {
    height: 100%;
    margin: auto;
}

.folder-struct {
    color: #000000 !important;
}

.name {
    color: #000 !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 4px;
}

</style>
