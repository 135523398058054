<template>
    <span class="text-field">


        <div class="uk-grid-collapse uk-flex uk-flex-middle" uk-grid>
            <div class="uk-width-expand">
                <LabelWithError v-if="label" :class="(isLabelHidden ? ' uk-hidden' : '')"
                                :error="errors.first(name)"
                                :is-label-large="isLabelLarge"
                                :label="$t(label, labelOptions) +labelInfo+ (rules ? (rules.includes('required') ? '*' : ' ('+$t('optional')+')') : '')"><slot
                    v-if="$slots.inLabel" name="inLabel"/></LabelWithError>
            </div>
            <div v-if="$slots.besidesLabel" class="besides-label">
                <slot name="besidesLabel"/>
            </div>
        </div>

        <div v-if="$slots.afterLabel">
            <slot name="afterLabel"/>
        </div>

        <div :class="'uk-inline uk-width-1-1' + (isHidden ? ' uk-hidden' : '')">
            <span v-if="icon" :uk-icon="'icon: '+icon" class="uk-form-icon"/>
            <input ref="inputRef"
                   :id="id"
                   v-validate="rules"
                   :autocomplete="autocomplete"
                   :autofocus="autofocus"
                   :class="classGenerator(name)"
                   :data-vv-as="$t(label)"
                   :disabled="disabled"
                   :name="name"
                   :placeholder="placeholder ? placeholder : (icon === 'search' ? $t('search') : '')"
                   :step="steps"
                   :title="title"
                   :type="overlay ? 'text' : (toggle ? 'text' : (type ? type : 'text'))"
                   :uk-tooltip="tooltip ? 'title:'+tooltip : ''"
                   :value="overlay ? overlay : value"
                   v-on:click="handleOnClick"
                   v-on:change="onChange"
                   v-on:input="updateValue($event.target.value)"
                   v-on:keyup.enter="onEnter"
            />

            <div v-if="type==='password'" :uk-icon="'icon:'+ (toggle ? 'eye-closed' : 'eye')"
                 class="reveal uk-form-icon uk-form-icon-flip cursor-pointer"
                 v-on:click="toggle= !toggle"/>

            <div v-if="icon==='search' && value && value.length>0"
                 class="reveal uk-form-icon uk-form-icon-flip cursor-pointer"
                 uk-icon="close"
                 v-on:click="updateValue($event.target.value)"/>

            <div v-if="actionIcon" :uk-icon="'icon:'+ actionIcon" :uk-tooltip="'title:'+actionTooltip"
                 class="reveal uk-form-icon uk-form-icon-flip cursor-pointer"
                 v-on:click="actionFunction"/>
        </div>

        <Password v-if="hasPasswordMeter" v-model="value" class="password-meter" strength-meter-only/>

        <div v-if="showKeyboard"
             class="uk-margin-small-top uk-animation-fade">
            <SimpleKeyboard
                @onChange="updateValue"
                :has-numpad="hasKeyboardNumpad"
                @onEnter="onEnter"
                :input="value"
                :keyboard-class="'simple-keyboard-'+name"/>
        </div>

    </span>
</template>

<script>

import Password from 'vue-password-strength-meter'
import LabelWithError from "@/components/generic/LabelWithError";
import {parsePhoneNumber} from 'awesome-phonenumber'
import {i18n} from "@/main";
import {useCurrencyInput} from 'vue-currency-input'
import SimpleKeyboard from "@/components/SimpleKeyboard.vue";
import UIkit from "uikit";
//import KioskBoard from 'kioskboard';
//import KioskBoardGerman from 'kioskboard/dist/kioskboard-keys-german.json';
// import KioskBoardEnglish from 'kioskboard/dist/kioskboard-keys-english.json';
// import KioskBoardFrench from 'kioskboard/dist/kioskboard-keys-french.json';


export default {
    name: 'TextField',
    components: {
        SimpleKeyboard,
        LabelWithError,
        Password,
    },
    props: {
        steps: Number,
        fieldKey: Number,
        identifier: Number,
        placeholder: String,
        name: String,
        rules: String,
        value: [String, Number],
        overlay: String,
        label: String,
        labelOptions: Object,
        labelInfo: {
            type: String,
            default: ''
        },
        translate: Boolean,
        type: String,
        icon: String,
        disabled: Boolean,
        simple: Boolean,
        validate: Number,
        onEnter: {
            type: Function,
            default: function () {
            },
        },
        onChange: {
            type: Function,
            default: function () {
            },
        },
        onClick: {
            type: Function,
            default: function () {
            },
        },
        onInput: {
            type: Function,
            default: function () {
            },
        },
        title: String,
        onUpdate: {
            type: Function,
            default: function () {
            },
        },
        autocomplete: {
            type: String,
            default: "off"
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        hasPasswordMeter: Boolean,
        tooltip: String,

        actionIcon: String,
        actionFunction: Function,
        actionTooltip: String,
        isDark: Boolean,
        isHidden: Boolean,
        isLabelHidden: Boolean,
        isOptional: Boolean,
        isLabelLarge: Boolean,
        isCurrency: Boolean,
        classes: String,
        hasKeyboard: Boolean,
        hasKeyboardNumpad: Boolean,

        enterOnInputLength: Number,
    },
    setup(props) {
        if (props.isCurrency) {
            const {inputRef} = useCurrencyInput({currency: 'EUR'})
            return {inputRef}
        }
    },
    mounted() {
        if (this.hasKeyboard) {
            let self = this;
            window.addEventListener("close-keyboards", function () {
                self.showKeyboard = false;
            });
        }

        /*
        KioskBoard.run('.js-virtual-keyboard', {
            keysArrayOfObjects: KioskBoardGerman,
            language: 'en',
            keysSpacebarText: 'Space',
            keysEnterText: 'Enter',

            keysNumpadArrayOfNumbers: null,
            keysSpecialCharsArrayOfStrings: [
                !'^#+$%½&/{}()[]=*?\-_|@€₺~æß<>,;.:`
            ],

            theme: 'flat',
            keysFontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
            allowRealKeyboard: true,
            allowMobileKeyboard: true,
            cssAnimations: true,
            cssAnimationsStyle: 'slide',
            cssAnimationsDuration: 300,
            autoScroll: true,
            capsLockActive: false,
            keysAllowSpacebar: true,
            keysFontSize: '18px',
            keysFontWeight: 'normal',
            keysIconSize: '20px',
            keysEnterCallback: undefined,
            keysEnterCanClose: true,
        });

         */
    },
    created() {
        this.$validator.extend(
            'unique', {
                getMessage: field => field + ' ' + this.$t('exists.already'),
                validate: (value, field) => {
                    return !field.includes(value);
                }
            });

        this.$validator.extend(
            'filename', {
                getMessage: field => field + ' ' + this.$t('contains.disallowed.characters') + ' : * / \\ ? " > < |',
                validate: (value) => {
                    return this.isFilenameValid(value);
                }
            });

        this.$validator.extend(
            'phone', {
                getMessage: field => field + ' ' + this.$t('phone.error'),
                validate: (value) => {
                    let phone = parsePhoneNumber(value, i18n.locale);
                    return phone.isValid();
                }
            });

        this.$validator.extend(
            'emailV2', {
                validate: (value) => {
                    if (!value.includes("@")) {
                        return false;
                    }

                    const parts = value.split("@");
                    if (parts[0].length > 64) {
                        return false;
                    }

                    if (parts[1].length > 255) {
                        return false;
                    }

                    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                    return this.$validator.verify(value, "email") && emailRegex.test(value);
                }
            });
    },
    data() {
        return {
            toggle: false,
            showKeyboard: false,
            id: 'textfield-' + this.name,
        }
    },
    watch: {
        validate() {
            this.validation();
        },
    },
    methods: {
        validation() {
            this.$validator.validate().then(valid => {
                if (!valid) {
                    this.$refs.inputRef.focus();
                }

                this.$emit('setValid', valid, this.fieldKey, this.identifier)
            });
        },
        handleOnClick() {
            if (this.hasKeyboard) {
                let event = new CustomEvent("close-keyboards");
                this.globalDispatchEvent(event);

                this.showKeyboard = true;

                let self = this;
                setTimeout(function () {
                    UIkit.scroll("#" + self.id, {offset: 40}).scrollTo("#" + self.id);
                }, 10);
            }

            this.onClick();
        },
        updateValue(value) {
            if (this.type === 'number') {
                value = parseFloat(value);
            }

            this.$emit('input', value, this.fieldKey, this.identifier);

            let self = this;

            setTimeout(function () {
                self.validation();
            }, 10);

            setTimeout(function () {
                self.onInput();

                if (self.enterOnInputLength && value.length === self.enterOnInputLength) {
                    self.onEnter();
                }
            }, 50);
        },
        classGenerator(name) {
            let classes = "js-virtual-keyboard uk-input";

            if (this.errors.first(name)) {
                classes += " danger";
            }

            if (this.simple) {
                classes += " simple";
            }

            if (this.isDark) {
                classes += " dark";
            }

            if (this.actionIcon) {
                classes += " with-icon";
            }

            if (this.classes) {
                classes += " " + this.classes;
            }

            return classes;
        },
    },
}
</script>

<style lang="scss" scoped>

.besides-label {
    margin-bottom: 8px;
}

.reveal {
    pointer-events: initial !important;
}

.uk-input {
    border-radius: $border-radius;
    border-color: $border-color !important;
    height: 50px;
    color: $site-background-hover !important;
    width: 100%;
    font-weight: 500;
    font-size: 16px;

    &:hover, &:focus {
        border-color: $info-color !important;
    }

    &.dark {
        &:hover, &:focus {
            border-color: $theme-color-hover !important;
        }
    }

    &.danger {
        background: rgba(240, 80, 80, .15);
        border-color: $theme-background !important;
    }

    &.simple {
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }
}

input {
    &:disabled {
        cursor: not-allowed;
        border-top-radius: $border-radius;
    }
}

.password-meter {
    margin-top: 15px;
}

.with-icon {
    cursor: auto;
    padding-right: 40px !important;
}

</style>
