<template>
    <div>

        <div uk-grid="masonry: pack;"
             class="uk-grid-mini uk-child-width-1-6@l uk-child-width-1-5@m uk-child-width-1-4@s uk-child-width-1-2">
            <div
                v-for="file in files"
                :key="file.path+file.name"
                v-on:click="actionSelectFile(file)">

                <ImageEnhanced
                    class="cursor-pointer"
                    :src="apiUrl+'/galleries/'+gallery.id+(file.path ? '/folders/'+file.path : '')+'/file-thumbnails/'+file.name+'?token='+token"
                    :alt="file.name"/>

                <Modal :id="modalIdShow+file.path+file.name" is-extended is-close-outside>
                    <div class="uk-grid" uk-grid>
                        <div class="uk-width-2-3@m uk-width-1-1">

                            <div class="uk-transition-toggle image-struct">
                                <div class="uk-position-relative cropper-struct2 uk-flex-center uk-text-center">


                                    <cropper
                                        v-if="shows[file.path+file.name]"
                                        :src="apiUrl+'/galleries/'+gallery.id+(file.path ? '/folders/'+file.path : '')+'/file-thumbnails/'+file.name+'?token='+token+'&original=false'"
                                        :debounce="600"
                                        :moveImage="true"
                                        :auto-zoom="false"
                                        :transitions="true"
                                        :resizeImage="{ wheel: true, adjustStencil: true, touch: true}"
                                        :ref="'cropper'+file.path+file.name"
                                        @change="handleCropperChange(file)"
                                        :default-size="{ width: 999999999, height: 999999999 }"
                                        class="cropper thumbnail uk-width-1-1"
                                        :stencil-props="{
                            aspectRatio: getCropperRatioByPictureKey(file),
                            class: 'cropper-stencil',
                            previewClass: 'cropper-stencil__preview',
                            draggingClass: 'cropper-stencil--dragging',
                            handlersClasses: {
                                default: 'cropper-handler',
                                eastNorth: 'cropper-handler--east-north',
                                westNorth: 'cropper-handler--west-north',
                                eastSouth: 'cropper-handler--east-south',
                                westSouth: 'cropper-handler--west-south',
                            },
                            handlersWrappersClasses: {
                                default: 'cropper-handler-wrapper',
                                eastNorth: 'cropper-handler-wrapper--east-north',
                                westNorth: 'cropper-handler-wrapper--west-north',
                                eastSouth: 'cropper-handler-wrapper--east-south',
                                westSouth: 'cropper-handler-wrapper--west-south',
                            },
                        }"
                                    />


                                    <span v-if="false &&selectedIndex > 0"
                                          v-on:click="actionPreviousPicture()"
                                          :uk-tooltip="'title:'+$t('previous.picture')"
                                          class="uk-position-center-left uk-position-small uk-hidden-hover uk-light cursor-pointer"
                                          href uk-slidenav-previous
                                          uk-slideshow-item="previous"/>

                                    <span v-if="false &&selectedIndex < (files.length-1)"
                                          v-on:click="actionNextPicture()"
                                          :uk-tooltip="'title:'+$t('next.picture')"
                                          class="uk-position-center-right uk-position-small uk-hidden-hover uk-light cursor-pointer"
                                          href uk-slidenav-next
                                          uk-slideshow-item="next"/>


                                </div>
                            </div>


                            <div class="picture-name uk-text-center">
                                {{ file.name }}
                            </div>


                        </div>

                        <div class="uk-width-1-3@m uk-width-1-1">


                            <div v-if="!unifiedFormat">
                                <div class="uk-grid-small uk-child-width-1-1"
                                     uk-grid>

                                    <div v-for="format in getFormats"
                                         :key="'f'+format">
                                        <div v-on:click="actionSelectFormat(format)">
                                            <LabelSelect

                                                :is-selected="format === unifiedFormat">
                                                <div class="uk-text-center paper-text">
                                                    {{ format }}
                                                </div>

                                            </LabelSelect>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div v-else>
                                <div v-on:click="actionResetFormat()">
                                    <LabelSelect
                                    >
                                        <div class="uk-text-center paper-text">
                                            <Translate
                                                translation-key="back.to.formats"/>
                                        </div>

                                    </LabelSelect>
                                </div>
                                <hr/>

                                <LabelSelect is-muted is-selected>
                                    <div class="uk-text-center paper-text">
                                        {{ unifiedFormat }}
                                    </div>
                                </LabelSelect>


                                <div class="uk-margin-top"/>
                                <div
                                    class="uk-height-1-1 uk-grid-small uk-child-width-1-2@l uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match"
                                    uk-grid>
                                    <div v-for="paper in getPapersByFormat(unifiedFormat)"
                                         :key="paper.value">
                                        <div
                                            v-on:click="actionSelectPaper(paper)">
                                            <LabelSelect
                                                :is-selected="paper.value === unifiedId">
                                                <div class="uk-text-center paper-text">
                                                    {{ getPaperName(paper.text) }}
                                                </div>
                                                <div class="uk-text-center paper-price">
                                                    {{ paper.price }}

                                                    <div v-if="paper.minPrice">
                                                        {{ paper.minPrice }}
                                                        {{ $t('minimum.price') }}
                                                    </div>
                                                </div>
                                            </LabelSelect>
                                        </div>
                                    </div>
                                </div>


                                <div class="uk-margin-top"/>
                                <div class="uk-grid-collapse" uk-grid>
                                    <div class="uk-width-auto">
                                                        <span v-on:click="manipulateAmount( -1)">
                                                            <Button mode="default" with-class="minus-button">
                                                                <Icon icon="minus"/>
                                                            </Button>
                                                        </span>
                                    </div>
                                    <div class="uk-width-expand uk-text-center item-amount">
                                        <input v-model.number="amount"
                                               v-on:input="validateAmount()"
                                               :placeholder="$t('amount')"
                                               min="1"
                                               class="uk-input uk-text-center" type="number">
                                    </div>
                                    <div class="uk-width-auto">
                                                        <span v-on:click="manipulateAmount( +1)">
                                                            <Button mode="default" with-class="plus-button">
                                                                <Icon icon="plus"/>
                                                            </Button>
                                                        </span>
                                    </div>
                                </div>

                                <div class="uk-margin-top"/>
                                <div
                                    v-if="getArticleByID(unifiedId) && getArticleByID(unifiedId).graduatedPrices && getArticleByID(unifiedId).graduatedPrices.length>0"
                                    class=" uk-margin-top">
                                    <div class=""/>

                                    <InfoBox class="graduated-prices">
                                        <div
                                            v-for="(graduatedPrice, key) in getArticleByID(unifiedId).graduatedPrices"
                                            :key="key">

                                                        <span
                                                            v-if="graduatedPrice.amount-getGroupedPicturesAmountByArticleID(unifiedId)>0">
                                                            {{
                                                                $t('x.at.x.amount.left.x', {
                                                                    price: getNumberWithCurrency(graduatedPrice.price) + (getArticleByID(unifiedId).isHeightVariable ? ' ' + $t('per.x', {x: $t(getInfos.measurementUnit)}) : ''),
                                                                    amount: graduatedPrice.amount,
                                                                    required: graduatedPrice.amount - getGroupedPicturesAmountByArticleID(unifiedId),
                                                                })
                                                            }}
                                                        </span>
                                            <span v-else>
                                                            {{
                                                    $t('x.at.x.amount', {
                                                        price: getNumberWithCurrency(graduatedPrice.price),
                                                        amount: graduatedPrice.amount,
                                                    })
                                                }}

                                                            <Icon :ratio="0.8" icon="check"/>
                                                        </span>

                                        </div>
                                    </InfoBox>
                                </div>


                                <div class="uk-margin-medium-top"/>
                                <span v-on:click="actionAdd(file)">
                                <Button mode="theme"
                                        is-max
                                        is-large>
                                    <Icon icon="plus"/>
                                    <Translate translation-key="add"/>
                                </Button>

                                    </span>

                            </div>

                        </div>

                        <div class="uk-width-1-1">
                            <Button is-large
                                    is-max
                                    has-action
                                    :action="actionOrderView"
                                    mode="success">

                                <Icon icon="cart"/>
                                {{ samples }}

                                <span class="uk-margin-small-left"/>
                                <Translate translation-key="cart"/>

                                {{ total }}
                            </Button>
                        </div>

                        <div class="uk-width-1-1">
                            <Button class="uk-modal-close"
                                    is-max
                                    is-large
                                    mode="default">
                                <Translate translation-key="close"/>
                            </Button>
                        </div>


                    </div>
                </Modal>

            </div>
        </div>


    </div>
</template>


<script>

import ImageEnhanced from "@/components/ImageEnhanced.vue";
import Icon from "@/components/generic/Icon.vue";
import UIkit from "uikit";
import Modal from "@/components/generic/Modal.vue";
import LabelSelect from "@/components/LabelSelect.vue";
import Translate from "@/components/Translate.vue";
import InfoBox from "@/components/generic/InfoBox.vue";
import Button from "@/components/generic/Button.vue";
import Vue from "vue";

export default {
    name: 'GalleryPublicFile',
    components: {Button, InfoBox, Translate, LabelSelect, Modal, Icon, ImageEnhanced},
    props: {
        triggerShow: Number,

        selectedFiles: Array,
        files: Array,
        total: String,
        samples: Number,
        modalIdOrderView: String,

        token: String,
        gallery: {
            id: String,
            name: String,
            thumbnail: String,
            articleListID:
                [{
                    id: String,
                    paper: String,
                    width: Number,
                    height: Number,
                    price: Number,
                    graduatedPrices: [{
                        amount: Number,
                        price: Number,
                    }],
                    isFavorite: Boolean,
                    isHeightVariable: Boolean,
                    dpi: Number,
                    name: String,
                    is: Boolean,
                }],
            folders: [{
                name: String,
                thumbnail: String,
            }],
            stats: {
                sizeUsageInBytes: Number,
                pictures: Number,
                folder: Number,
            }
        },

        file: {
            name: String,
            isDir: Boolean,
            path: String,

            width: Number,
            height: Number,
        },

        folder: Object,

    },
    data() {
        return {
            shows: [],

            show: false,

            modalIdShow: 'modal-gallery-file',
            slideshowId: 'slideshow-gallery-files',

            unifiedFormat: "",
            unifiedId: "",
            unifiedPaper: "",
            amount: 1,
            coordinates: null,
            cropped: null,

            selectedIndex: 0,
        }
    },
    mounted() {
        /*
        let self = this;

        const modalElement = document.getElementById(this.modalIdShow);
        modalElement.addEventListener("show", function () {
            self.show = true;
        });

        modalElement.addEventListener("hidden", function () {
            self.actionResetFormat();

            // self.show = false;
        });
         */
    },
    computed: {
        getFormats() {
            let formats = [];

            let articles = this.gallery.articleListID;

            if (!articles) {
                return formats;
            }

            articles = articles.slice().sort(this.articleFormatSortFunc);

            for (let i = 0, j = articles.length; i < j; i++) {
                let format = this.getArticleFormat(articles[i]);
                if (!formats.includes(format)) {
                    formats.push(format);
                }
            }

            return formats;
        },
    },
    methods: {
        actionOrderView() {
            UIkit.modal("#" + this.modalIdOrderView).show();
        },
        manipulateAmount(manipulator) {
            this.amount += manipulator;
            this.validateAmount();
            this.$forceUpdate();
        },
        validateAmount() {
            if (!this.amount || this.amount === 0) {
                this.amount = 1;
            }

            if (this.amount < 0) {
                this.amount *= -1;
            }
        },
        actionNextPicture() {
            this.selectedIndex++;
            this.$forceUpdate();
        },
        actionPreviousPicture() {
            this.selectedIndex--;
            this.$forceUpdate();
        },
        actionAdd(file) {
            this.$emit("add", file, this.unifiedId, this.amount, this.coordinates, this.cropped);

            UIkit.notification({
                message: this.$t('picture.added.to.cart'),
                status: 'info',
                pos: 'top-center',
                timeout: this.notificationTimeout,
            });

            this.actionResetFormat();
        },
        actionSelectPaper(paper) {
            this.unifiedId = paper.value;
            this.unifiedPaper = paper.text;

            this.$forceUpdate();
        },
        actionSelectFormat(format) {
            this.unifiedFormat = format;
            this.unifiedId = this.getFirstArticleIDByFormat(format);

            this.$forceUpdate();
        },
        getPapersByFormat(format) {
            let papers = [];

            let articles = this.gallery.articleListID;
            for (let i = 0, j = articles.length; i < j; i++) {

                let rowFormat = this.getArticleFormat(articles[i]);

                if (format && format !== rowFormat) {
                    continue;
                }

                let priceString = this.getNumberWithCurrency(articles[i].price);
                if (articles[i].isHeightVariable) {
                    priceString += " " + this.$t('per.x', {x: this.$t(this.getInfos.measurementUnit)});
                }

                let minPriceString = null;
                if (articles[i].isHeightVariable && articles[i].minPrice) {
                    minPriceString = this.getNumberWithCurrency(articles[i].minPrice)
                }

                papers.push({
                    value: articles[i].id,
                    text: articles[i].paper,
                    price: priceString,
                    minPrice: minPriceString,
                });
            }

            return papers;
        },
        actionResetFormat() {
            this.unifiedFormat = "";
            this.unifiedId = "";
            this.unifiedPaper = "";
            this.amount = 1;
            this.coordinates = null;
            this.cropped = null;

            this.$forceUpdate();
        },
        getFavoriteArticle() {
            let articles = this.gallery.articleListID;

            if (articles) {
                for (let i = 0, j = articles.length; i < j; i++) {
                    if (articles[i].isFavorite) {
                        return articles[i];
                    }
                }
            }

            return null;
        },
        getFirstArticleIDByFormat(format, paper) {
            if (!format) {
                if (!this.gallery.articleListID) {
                    return 0;
                }

                let favoriteArticle = this.getFavoriteArticle();
                if (favoriteArticle) {
                    return favoriteArticle.id;
                }

                return this.gallery.articleListID[0].id;
            }

            let articles = this.gallery.articleListID;

            articles = articles.sort((a, b) => Number(b.isFavorite) - Number(a.isFavorite));

            articles = articles.sort(this.articleFormatSortFunc)

            if (paper) {
                paper = this.getPaperName(paper);

                for (let i = 0, j = articles.length; i < j; i++) {
                    let parsedFormat = this.getArticleFormat(articles[i]);
                    if (format === parsedFormat && paper === this.getPaperName(articles[i].paper)) {
                        return articles[i].id;
                    }
                }
            }

            for (let i = 0, j = articles.length; i < j; i++) {
                let parsedFormat = this.getArticleFormat(articles[i]);
                if (format === parsedFormat) {
                    return articles[i].id;
                }
            }
        },
        getArticleFormat(article) {
            if (article.name) {
                return article.name;
            }

            if (article.isHeightVariable) {
                return this.roundWidth(article.width) + ' ' + this.$t(this.getInfos.measurementUnit) + ' x ' + this.$t('pictureLength');
            }

            return this.roundWidth(article.width) + ' x ' + this.roundHeight(article.height) + ' ' + this.$t(this.getInfos.measurementUnit);
        },
        articleFormatSortFunc(a, b) {
            if (a.width < b.width) return -1;
            if (a.width > b.width) return 1;
            return 0;
        },
        getArticleByID(articleID) {
            let articles = this.gallery.articleListID;
            for (let i = 0, j = articles.length; i < j; i++) {
                if (articles[i].id === articleID) {
                    return articles[i];
                }
            }
        },
        getGroupedPicturesAmountByArticleID(articleID) {
            let amount = 0;

            let pictures = this.selectedFiles;
            for (let i = 0, j = pictures.length; i < j; i++) {
                if (this.unifiedId === articleID) {
                    amount += pictures[i].amount;
                }
            }

            amount += this.amount;

            return amount;
        },
        getCropperRatioByPictureKey(file) {
            const item = file;
            const article = this.getArticleByID(this.unifiedId);

            if (!article) {
                return null;
            }

            if (article.isHeightVariable) {
                return null;
            }

            // hochformat
            if (item.height > item.width) {
                if (article.height > article.width) {
                    return article.width / article.height;
                }

                return article.height / article.width;
            }

            // querformat
            if (article.height > article.width) {
                return article.height / article.width;
            }

            return article.width / article.height;
        },
        areCoordinatesEqual(oldCoordinates, newCoordinates) {
            if (!oldCoordinates) {
                return false;
            }

            if (oldCoordinates.height !== newCoordinates.height) {
                return false;
            }

            if (oldCoordinates.left !== newCoordinates.left) {
                return false;
            }

            if (oldCoordinates.top !== newCoordinates.top) {
                return false;
            }

            if (oldCoordinates.width !== newCoordinates.width) {
                return false;
            }

            return true;
        },
        handleCropperChange(file) {
            const {coordinates, canvas} = this.$refs['cropper' + file.path + file.name][0].getResult();

            if (!coordinates || (coordinates.height === 0 && coordinates.width === 0 && coordinates.left === 0 && coordinates.top === 0)) {
                return;
            }

            if (this.areCoordinatesEqual(this.coordinates, coordinates)) {
                return;
            }

            this.coordinates = coordinates;

            // this.getAndSetMeasurement(key)

            let self = this;
            canvas.toBlob(
                (blob) => {
                    this.cropped = self.getLocalFileUrl(blob);
                },
                "image/png",
                1.0,
            );
        },
        actionSelectFile(file) {
            Vue.set(this.shows, file.path + file.name, true);
            const modalElement = document.getElementById(this.modalIdShow + file.path + file.name);
            UIkit.modal(modalElement).show();

            let self = this;
            setTimeout(function () {
                self.$forceUpdate();
            }, 100);
        },
    }
}

</script>


<style lang="scss" scoped>

.name {
    color: #000 !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 4px;
}

.cropper {
    background: #000;
}

.cropper-struct2 {
    padding: 15px;
    background: #111;
}

.modal-struct {
    height: 80vh;
}

.picture-name {
    font-size: 16px;
    margin-top: 10px;
}

.paper-text {
    font-weight: 500;
    word-break: break-all;
    padding: 5px;
}

.thumbnail {
    height: 425px;
    color: #fff;
}

.scrollable {
    overflow: auto;
}

.overlay-fix {
    margin-left: 15px !important;
}

.uk-grid-mini,
.uk-grid-column-small {
    margin-left: -5px;
}

.uk-grid-mini > *,
.uk-grid-column-small > * {
    padding-left: 5px;
}

/* Vertical */
.uk-grid + .uk-grid-mini,
.uk-grid + .uk-grid-row-small,
.uk-grid-mini > .uk-grid-margin,
.uk-grid-row-small > .uk-grid-margin,
* + .uk-grid-margin-small {
    margin-top: 5px;
}

</style>
