<template>
    <div class="site-struct full">
        <LoadingStruct is-dark :is-loading="loading">

            <div class="galleries-struct2" v-if="invalid">
                <div class="total-struct uk-padding">
                    <div class="uk-grid" uk-grid>
                        <div class="uk-flex uk-flex-middle">
                            <div>
                                <LinkObject to="">
                                    <img v-if="getInfos.logo"
                                         :src="apiUrl+'/logos/'+getInfos.logo" class="logo infos-name"/>
                                    <span class="iname uk-visible@m">
                                        {{ getInfos.name }}
                                    </span>
                                </LinkObject>
                            </div>

                            <div class="uk-width-expand uk-invisible"/>
                            <div class="uk-flex uk-flex-middle uk-invisible">
                            <span class="gallery-name">
                                {{ getInfos.name }}
                            </span>
                            </div>
                            <div class="uk-invisible">
                                <Button is-large
                                        has-action

                                        mode="theme">

                                    <Icon icon="cart"/>


                                    <span class="uk-margin-small-left"/>
                                    <Translate translation-key="cart"/>


                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <Card :title="$t('gallery.link.invalid')" is-centered is-dark mode="default">
                    <LinkObject to="">
                        <Button mode="primary" is-large>
                            <Translate translation-key="back.to.start"/>
                        </Button>
                    </LinkObject>
                </Card>

            </div>


            <div v-else class="galleries-struct">

                <Modal is-medium :id="modalIdFolders" :title="$t('folders')">

                    <div uk-grid
                         class="uk-child-width-1-3@xl uk-child-width-1-2@l uk-child-width-1-2@m uk-child-width-1-1@s uk-child-width-1-1 uk-grid-match uk-flex uk-flex-center">

                        <div class="uk-width-1-1">
                            <span v-on:click="actionSelectFolder(null)">
                            <GalleryFolderItem :galleryID="gallery.id"
                                               :token="token"
                                               is-root
                                               :folder="{
                                name: $t('folder.root'),
                                thumbnail: gallery.thumbnail,
                                stats: gallery.stats,
                            }"/>
                                </span>
                        </div>

                        <div :class="gallery.folders.length === 1 ? 'uk-width-1-1' : ''"
                             v-for="folder in gallery.folders" :key="folder.name">
                            <span v-on:click="actionSelectFolder(folder)">


                            <GalleryFolderItem
                                :galleryID="gallery.id"
                                :token="token"
                                :folder="folder"/>
                                  </span>
                        </div>
                    </div>


                    <div class="uk-margin-medium-top"/>
                    <Button class="uk-modal-close" :text="$t('close')" is-large is-max
                            mode="default"/>
                </Modal>

                <Modal :id="modalIdOrderSuccess" :title="$t('order.created.title')">
                    <SuccessMessage without-animation success>{{ $t('paid.order.success') }}</SuccessMessage>

                    <div class="uk-margin-medium-bottom"/>
                    <Button :action="actionReset" :text="$t('order.more.pictures')" has-action is-large is-max
                            mode="primary"/>

                    <hr v-if="false">
                    <Button v-if="false" :text="$t('close')" class="uk-modal-close" is-large is-max mode="default"/>

                    <div v-if="!isApp">
                        <PWAPromptForm v-if="getInfos && getInfos.name" :name="getInfos.name"
                                       :prompt-installer-event="promptInstallerEvent"/>
                        <PWAHintForm v-if="getInfos && getInfos.name" :name="getInfos.name"/>
                    </div>

                </Modal>

                <Modal :title="$t('cart')"
                       is-medium
                       :id="modalIdOrderView">

                    <div v-if="selectedFiles.length === 0" class="uk-text-center">
                        <Translate translation-key="cart.empty"/>

                    </div>
                    <div v-else>

                        <ul class="uk-child-width-expand"
                            id="cart-tab"
                            uk-tab="animation: uk-animation-fade">
                            <li v-for="tab in ['pictures', 'summary']"
                                :key="tab">
                                <a href="#">{{ $t(tab) }}</a>
                            </li>
                        </ul>
                        <ul class="uk-switcher uk-margin">
                            <li>


                                <div class="section-title">{{ $t('pictures') }}</div>

                                <div v-for="(item, originKey) in selectedFiles" :key="'p'+originKey" class=""
                                     uk-grid>
                                    <div class="uk-width-medium@s uk-width-small">
                                        <div class="uk-inline uk-transition-toggle image-struct">

                                            <img
                                                :src="item.cropped"/>

                                            <div class="picture-name uk-text-center">
                                                {{ item.name }}
                                            </div>


                                        </div>
                                    </div>
                                    <div class="uk-width-expand">
                                        <div class="uk-grid-small uk-child-width-1-1" uk-grid>

                                            <div class="uk-grid-collapse" uk-grid>
                                                <div class="uk-width-auto">
                                                        <span v-on:click="manipulateAmount(originKey, -1)">
                                                            <Button mode="default" with-class="minus-button">
                                                                <Icon
                                                                    :uk-tooltip="'title:'+$t('remove.picture')"
                                                                    v-if="item.amount === 1"
                                                                    icon="trash"/>
                                                                <Icon v-else icon="minus"/>
                                                            </Button>
                                                        </span>
                                                </div>
                                                <div class="uk-width-expand uk-text-center item-amount">
                                                    <input v-model.number="item.amount"
                                                           v-on:input="validateAmount(originKey)"
                                                           :placeholder="$t('amount')"
                                                           min="1"
                                                           class="uk-input uk-text-center" type="number">
                                                </div>
                                                <div class="uk-width-auto">
                                                        <span v-on:click="manipulateAmount(originKey, +1)">
                                                            <Button mode="default" with-class="plus-button">
                                                                <Icon icon="plus"/>
                                                            </Button>
                                                        </span>
                                                </div>
                                            </div>

                                            <div class="uk-margin-small-top"/>
                                            <div class="uk-width-auto@s uk-width-1-1">
                                                <span class="uk-badge badge">{{ item.amount }}x</span>
                                            </div>
                                            <div class="uk-width-auto@s uk-width-1-1">
                                                {{ getFormatByArticleID(item.articleID, true) }} ({{
                                                    getPriceByArticleID(item.articleID, item.amount)
                                                }}{{
                                                    getArticleByID(item.articleID).isHeightVariable ? ' ' + $t('per.x', {x: $t(getInfos.measurementUnit)}) : ''
                                                }}{{
                                                    getArticleByID(item.articleID).isHeightVariable && getArticleByID(item.articleID).minPrice ? ', ' + $t('min') + ' ' + getNumberWithCurrency(getArticleByID(item.articleID).minPrice) : ''
                                                }})
                                            </div>

                                            <div v-if="isVariableHeight(item.articleID)">
                                                <div class="uk-grid-small" uk-grid>
                                                    <div class="uk-width-expand" uk-leader>
                                                        {{ $t('pictureLength') }}
                                                        ({{ $t(getPictureFormatName(originKey) + '.format') }})
                                                    </div>
                                                    <div>
                                                        <div v-if="item.measurementValue===0" uk-spinner/>
                                                        <span v-else>
                                                    {{
                                                                formatMeasurement(item.measurementValue)
                                                            }} {{ $t(getInfos.measurementUnit) }}
                                                </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div class="uk-grid-small" uk-grid>
                                                    <div class="uk-width-expand" uk-leader>
                                                        {{ $t('price') }}
                                                    </div>
                                                    <div>{{
                                                            getNumberWithCurrency(getCostsByAmountAndArticleId(item))
                                                        }}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div class="uk-margin-medium-top"/>
                                <div class="uk-child-width-expand@m uk-child-width-1-1" uk-grid>
                                    <div class="uk-visible@m">
                                        <Button class="uk-modal-close" :text="$t('cancel')" is-large is-max
                                                mode="default"/>

                                    </div>
                                    <div>
                                        <Button has-action
                                                is-large
                                                mode="theme"
                                                is-max
                                                :action="actionNext">
                                            <Translate translation-key="continue"/>
                                        </Button>
                                    </div>
                                    <div class="uk-hidden@m">
                                        <Button class="uk-modal-close" :text="$t('cancel')" is-large is-max
                                                mode="default"/>

                                    </div>
                                </div>

                            </li>
                            <li>

                                <div class="section-title">{{ $t('contact.details') }}</div>
                                <span v-for="(inputField, key) in contactFields" :key="'i'+inputField.name">
                            <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                                       :autofocus="inputField.autofocus" :field-key="key"
                                       :hasPasswordMeter="inputField.hasPasswordMeter"
                                       :icon="inputField.icon"
                                       :is-optional="inputField.optional"
                                       :label="inputField.label"
                                       :name="inputField.name"
                                       :on-enter="validateContactDetails"
                                       :rules="inputField.rules"
                                       :type="inputField.type"
                                       :validate="triggerContactFields"
                                       @setValid="setValidContactFields"
                                       :has-keyboard="isTerminalMode"
                            />
                            <div v-if="key < contactFields.length-1" class="uk-margin-bottom"/>
                        </span>

                                <div v-if="getInfos.mandatoryFields.includes('address')">
                                    <div class="uk-margin-bottom"/>
                                    <span v-for="(inputField, key) in addressFields" :key="'ia'+inputField.name">
                            <TextField v-model="inputField.value"
                                       :autocomplete="inputField.autocomplete"
                                       :autofocus="inputField.autofocus" :field-key="key"
                                       :hasPasswordMeter="inputField.hasPasswordMeter"
                                       :icon="inputField.icon"
                                       :is-optional="inputField.optional"
                                       :label="inputField.label"
                                       :name="inputField.name"
                                       :rules="inputField.rules"
                                       :type="inputField.type"

                                       :validate="triggerContactFields"
                                       :on-enter="validateContactDetails"
                                       @setValid="setValidAddressFields"
                            />
                            <div v-if="key < contactFields.length-1" class="uk-margin-bottom"/>
                        </span>

                                    <div class="uk-margin-bottom"/>
                                    <LabelWithError :label="$t('country')+'*'"/>
                                    <form autocomplete="off">
                                        <select v-model="country"
                                                name="country"
                                                disabled
                                                class="uk-select uk-input-styled">
                                            <option v-for="option in [getInfos.country]"
                                                    :key="option"
                                                    :value="option">{{ $t(option) }}
                                            </option>
                                        </select>
                                    </form>
                                </div>


                                <div class="uk-margin-medium-bottom"/>
                                <TextArea v-model="comments" :is-disabled="successOrder || loadingOrder" is-label-large
                                          label="comments"
                                          name="comments"
                                          :has-keyboard="isTerminalMode"
                                          rules="max:4096"/>


                                <div class="uk-margin-medium-bottom"/>
                                <div class="section-title">{{ $t('payment.method') }}</div>

                                <div class="uk-grid-small uk-child-width-1-2" uk-grid>
                                    <div v-for="method in getInfos.paymentMethods" :key="method">

                                <span v-on:click="setPaymentMethod(method)">
                                    <LabelSelect :is-disabled="successOrder || loadingOrder" with-line-height
                                                 :is-selected="paymentMethod === method">
                                        <div class="uk-text-center paper-text">
                                            <Icon class="icon-distance" :icon="getIconByName(method)"/>
                                            {{ $t(method) }}
                                        </div>
                                    </LabelSelect>
                                </span>

                                    </div>
                                </div>


                                <div :class="isAddressRequired ? '' : 'uk-hidden'">
                                    <div class="uk-margin-medium-bottom"/>
                                    <div class="section-title">{{ $t('address') }}</div>

                                    <div class="uk-grid-small" uk-grid>
                                        <div v-for="(inputField, key) in addressFields" :key="'i'+inputField.name"
                                             :class="key > 0 ? 'uk-width-1-2@s uk-width-1-1' : 'uk-width-1-1'">
                                            <TextField v-model="inputField.value"
                                                       :autocomplete="inputField.autocomplete"
                                                       :autofocus="inputField.autofocus" :field-key="key"
                                                       :hasPasswordMeter="inputField.hasPasswordMeter"
                                                       :icon="inputField.icon"
                                                       :is-optional="inputField.optional"
                                                       :label="inputField.label"
                                                       :name="inputField.name"
                                                       :on-enter="actionOrder"
                                                       :rules="inputField.rules"
                                                       :type="inputField.type"
                                                       :disabled="successOrder || loadingOrder"

                                                       :validate="triggerAddressFields"
                                                       @setValid="setValidAddressFields"
                                            />
                                        </div>

                                        <div class="uk-width-1-1">
                                            <LabelWithError :label="$t('country')+'*'"/>
                                            <form autocomplete="off">
                                                <select v-model="country"
                                                        name="country"
                                                        disabled
                                                        class="uk-select uk-input-styled">
                                                    <option v-for="option in [getInfos.country]"
                                                            :key="option"
                                                            :value="option">{{ $t(option) }}
                                                    </option>
                                                </select>
                                            </form>
                                        </div>

                                    </div>

                                </div>


                                <div class="uk-margin-medium-bottom"/>
                                <div class="section-title">{{ $t('delivery.type') }}</div>

                                <div class="uk-grid-small uk-child-width-1-2" uk-grid>
                                    <div v-for="method in getDeliveryTypeOptions" :key="method">

                                    <span v-on:click="setDeliveryTypes(method)">
                                        <LabelSelect :is-disabled="successOrder || loadingOrder" with-line-height
                                                     :is-selected="deliveryType === method">
                                            <div class="uk-text-center paper-text">
                                                <Icon class="icon-distance" :icon="getIconByName(method)"/>
                                                {{ $t(method) }}
                                            </div>
                                        </LabelSelect>
                                    </span>

                                    </div>
                                </div>


                                <div class="uk-margin-medium-bottom"/>
                                <div class="section-title">{{ $t('price') }}</div>

                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-expand" uk-leader>
                                        <span class="uk-badge badge">{{ getTotalSamplesCount }}</span>
                                        {{ $t(getTotalSamplesCount === 1 ? 'picture' : 'pictures') }}
                                    </div>
                                    <div>{{ getNumberWithCurrency(getTotalSamplesPrice) }}</div>
                                </div>


                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-expand" uk-leader>
                                        {{ $t('service.fee') }}

                                        <span v-if="getInfos.feeDiscount != null" class="graduated-prices">
                                    ({{ $t('dropped.at') }} {{
                                                getNumberWithCurrency(getInfos.feeDiscount)
                                            }}:
                                    <span v-if="isFeeDiscount">
                                        <Icon :ratio="0.8" icon="check"/>)
                                    </span>
                                    <span v-else>
                                        {{
                                            $t('left')
                                        }} {{ getNumberWithCurrency(getInfos.feeDiscount - getTotalSamplesPrice) }})
                                    </span>
                                </span>
                                    </div>
                                    <div>{{ getNumberWithCurrency(getFee) }}</div>
                                </div>


                                <div class="uk-grid-small uk-margin-small-medium-top" uk-grid>
                                    <div class="uk-width-expand" uk-leader>{{ $t('shipping.costs') }}</div>
                                    <div>{{ getNumberWithCurrency(getShippingCosts) }}</div>
                                </div>
                                <div
                                    v-if="false && isAddressRequired && getInfos.shippingGraduatedPrices && getInfos.shippingGraduatedPrices.length>0"
                                    class="uk-margin-top uk-margin-medium-bottom">
                                    <InfoBox class="graduated-prices">
                                        <div v-for="(graduatedPrice, key) in getInfos.shippingGraduatedPrices"
                                             :key="key">
                                        <span v-if="graduatedPrice.amount-getTotalSamplesCount>0">
                                            {{
                                                $t('x.at.x.amount.left.x', {
                                                    price: getNumberWithCurrency(graduatedPrice.price),
                                                    amount: graduatedPrice.amount,
                                                    required: graduatedPrice.amount - getTotalSamplesCount,
                                                })
                                            }}
                                        </span>
                                            <span v-else>
                                            {{
                                                    $t('x.at.x.amount',
                                                        {
                                                            price: getNumberWithCurrency(graduatedPrice.price),
                                                            amount: graduatedPrice.amount,
                                                        })
                                                }}
                                            <Icon :ratio="0.8" icon="check"/>
                                        </span>

                                        </div>
                                    </InfoBox>
                                </div>


                                <div class="uk-grid-small uk-margin-small-medium-top total-costs-text" uk-grid>
                                    <div class="uk-width-expand" uk-leader>{{ $t('total') }}</div>
                                    <div>{{ getTotal }}</div>
                                </div>
                                <div class="vat">
                                    {{ $t('vat.message', {vat: getInfos.vat}) }}
                                </div>


                                <div class="uk-margin-medium-bottom"/>
                                <div class="section-title">{{ $t('termsAndCondition.and.dataPrivacy') }}</div>
                                <Checkbox v-model="confirmTerms" :disabled="successOrder || loadingOrder"
                                          :is-disabled="successOrder || loadingOrder"
                                          :validate="triggerTerms"
                                          label="termsAndConditions.confirm" name="terms" rules="required"
                                          @setValid="setTermsValid"/>


                                <div v-if="!successOrder" class="uk-margin-medium-bottom"/>
                                <div v-if="!successOrder" class="uk-child-width-expand@m uk-child-width-1-1" uk-grid>
                                    <div class="uk-visible@m">
                                        <Button has-action
                                                :text="$t('back')"
                                                is-large
                                                is-max
                                                :action="actionBack"
                                                mode="default"/>

                                    </div>
                                    <div>
                                        <Button :action="actionOrder"
                                                :is-loading="loadingOrder"
                                                :text="$t('paid.order')" has-action
                                                is-large is-max
                                                mode="success"/>
                                    </div>
                                    <div class="uk-hidden@m">
                                        <Button has-action
                                                :text="$t('back')"
                                                is-large
                                                is-max
                                                :action="actionBack"
                                                mode="default"/>

                                    </div>
                                </div>

                                <div v-if="errorOrder" class="uk-margin-medium-bottom"/>
                                <ErrorMessage :custom-errors="[{code: 408, message: $t('session.timed.out')}]"
                                              :error="errorOrder"/>

                                <div v-if="successOrder" class="uk-margin-medium-bottom"/>
                                <SuccessMessage :success="successOrder">
                                    {{ $t('paid.order.success') }}

                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="reload" :text="$t('order.more.pictures')" has-action is-large
                                            is-max
                                            mode="primary"/>
                                </SuccessMessage>
                            </li>
                        </ul>

                    </div>
                </Modal>


                <div :id="modalIdIntro" class="uk-modal-full uk-modal-close cursor-pointer" uk-modal>
                    <div class="uk-modal-dialog">
                        <div class="uk-grid-collapse uk-child-width-1-2@m uk-child-width-1-1 uk-flex-middle" uk-grid>

                            <div class="uk-background-cover uk-animation-fade" uk-height-viewport
                                 :data-src="gallery.thumbnail ? apiUrl+'/galleries/'+gallery.id+'/thumbnail/'+gallery.thumbnail+'?token='+token : require('../assets/default_thumbnail.jpg')"
                                 uk-img="loading: eager">

                                <div
                                    class="uk-position-center gallery-mobile uk-hidden@m uk-padding-large uk-text-center uk-animation-fade">
                                    <h2>{{ gallery.name }}</h2>

                                    <div class="uk-margin-top"/>
                                    <Button mode="theme" is-large class="uk-modal-close">
                                        <Translate translation-key="open.gallery"/>
                                    </Button>
                                </div>

                            </div>


                            <div class="uk-padding-large uk-text-center uk-animation-fade uk-visible@m">
                                <h1>{{ gallery.name }}</h1>

                                <div class="uk-margin-top"/>
                                <Button mode="theme" is-large class="uk-modal-close">
                                    <Translate translation-key="open.gallery"/>
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>


                <div class="total-struct uk-padding">
                    <div class="uk-grid" uk-grid>
                        <div class="uk-flex uk-flex-middle">
                            <div>
                                <LinkObject to="">
                                    <img v-if="getInfos.logo"
                                         :src="apiUrl+'/logos/'+getInfos.logo" class="logo infos-name"/>
                                    <span class="iname uk-visible@m">
                                        {{ getInfos.name }}
                                    </span>
                                </LinkObject>
                            </div>
                        </div>
                        <div class="uk-width-expand"/>


                        <div class="uk-flex uk-flex-middle uk-visible@m">
                            <span v-on:click="showFolderSelection()"
                                  :uk-tooltip="gallery.folders ? 'title:'+$t('folder.switch') : ''"
                                  :class="gallery.folders ? 'cursor-pointer' : ''">

                                <span class="gallery-name">
                                    {{ gallery.name }}
                                    <span v-if="selectedFolder">
                                        / {{ selectedFolder.name }}
                                    </span>
                                </span>

                                <Icon v-if="gallery.folders"
                                      icon="chevron-down"
                                      class="dark"
                                      :ratio="1"/>
                            </span>
                        </div>
                        <div>
                            <Button is-large
                                    has-action
                                    :action="actionOrderView"
                                    mode="success">

                                <Icon icon="cart"/>
                                {{ getTotalSamplesCount }}

                                <span class="uk-margin-small-left"/>
                                <Translate translation-key="cart"/>

                                {{ getTotal }}
                            </Button>
                        </div>
                    </div>

                </div>


                <Card is-dark mode="default" is-without-body class="uk-padding-small">
                    <div class="gallery-name uk-hidden@m uk-margin-bottom uk-text-center">

                        <span v-on:click="showFolderSelection()"
                              :uk-tooltip="gallery.folders ? 'title:'+$t('folder.switch') : ''"
                              :class="gallery.folders ? 'cursor-pointer' : ''">

                        {{ gallery.name }}
                            <span v-if="selectedFolder">
                                        / {{ selectedFolder.name }}
                                    </span>

                        <Icon v-if="gallery.folders"
                              icon="chevron-down"
                              class="dark"
                              :ratio="1"/>
                            </span>
                    </div>


                    <div v-if="filesLoading"
                         class="uk-flex-center">
                        <div uk-spinner/>
                    </div>
                    <div v-else-if="files && files.length>0">

                        <GalleryPublicFile
                            :files="files"
                            :total="getTotal"
                            :folder="selectedFolder"
                            :samples="getTotalSamplesCount"
                            :selected-files="selectedFiles"
                            :gallery="gallery"
                            :modal-id-order-view="modalIdOrderView"
                            :token="token"
                            @add="handleAdd"
                        />

                    </div>
                    <div class="uk-text-center iname" v-else>
                        <Translate translation-key="none.x" :parameter="{x: $t('pictures')}"/>
                    </div>


                </Card>

            </div>
        </LoadingStruct>

    </div>
</template>

<script>

import LoadingStruct from "@/views/LoadingStruct";
import axios from "axios";
import Card from "@/components/generic/Card.vue";
import Button from "@/components/generic/Button.vue";
import Translate from "@/components/Translate.vue";
import LinkObject from "@/components/LinkObject.vue";
import {parse as uuidParse} from 'uuid';
import UIkit from "uikit";
import InfoBox from "@/components/generic/InfoBox.vue";
import Icon from "@/components/generic/Icon.vue";
import Modal from "@/components/generic/Modal.vue";
import SuccessMessage from "@/components/generic/SuccessMessage.vue";
import ErrorMessage from "@/components/generic/ErrorMessage.vue";
import TextArea from "@/components/TextArea.vue";
import LabelSelect from "@/components/LabelSelect.vue";
import TextField from "@/components/generic/TextField.vue";
import Checkbox from "@/components/generic/Checkbox.vue";
import LabelWithError from "@/components/generic/LabelWithError.vue";
import PWAPromptForm from "@/components/PWAPromptForm.vue";
import PWAHintForm from "@/components/PWAHintForm.vue";
import GalleryPublicFile from "@/components/forms/GalleryPublicFile.vue";
import GalleryFolderItem from "@/components/GalleryFolderItem.vue";


export default {
    name: 'Gallery',
    components: {
        GalleryFolderItem,
        GalleryPublicFile,
        PWAHintForm, PWAPromptForm,
        LabelWithError, Checkbox, TextField, LabelSelect, TextArea, ErrorMessage, SuccessMessage,
        Modal,
        Icon, InfoBox,

        LinkObject,
        Translate,
        Button,
        Card,
        LoadingStruct,
    },
    data() {
        return {
            modalIdIntro: 'modal-id',
            introInterval: 0,

            modalIdOrderView: 'modal-order-view',
            modalIdOrderSuccess: "modal-order-success",
            modalIdFolders: 'modal-folders',

            isApp: true,
            promptInstallerEvent: null,


            loading: true,
            error: null,
            success: false,
            invalid: false,

            gallery: {
                id: String,
                name: String,
                thumbnail: String,
                articleListID:
                    [{
                        id: String,
                        paper: String,
                        width: Number,
                        height: Number,
                        price: Number,
                        graduatedPrices: [{
                            amount: Number,
                            price: Number,
                        }],
                        isFavorite: Boolean,
                        isHeightVariable: Boolean,
                        dpi: Number,
                        name: String,
                        is: Boolean,
                    }],
                folders: [{
                    name: String,
                    thumbnail: String,
                }],
                stats: {
                    sizeUsageInBytes: Number,
                    pictures: Number,
                    folder: Number,
                }
            },

            selectedFiles: [],
            files: [],
            filesLoading: true,

            selectedFolder: null,


            token: "",


            triggerContactFields: 0,
            contactFields: [
                {
                    value: "",
                    valid: false,

                    name: "name",
                    label: "name",
                    icon: "user",
                    rules: "required|max:320",
                },
                {
                    value: "",
                    valid: false,

                    name: "email",
                    label: "emailAddress",
                    icon: "mail",
                    rules: "required|emailV2",
                },
                {
                    value: "",
                    valid: true,

                    name: "telephone",
                    label: "telephone",
                    icon: "receiver",
                    rules: "max:80",
                },
            ],

            triggerAddressFields: 0,
            addressFields: [
                {
                    value: "",
                    valid: false,

                    name: "street",
                    label: "street",
                    icon: "home",
                    rules: "required|max:320",
                },
                {
                    value: "",
                    valid: false,

                    name: "zip",
                    label: "zip",
                    icon: "location",
                    rules: "required|numeric|max:320",
                },
                {
                    value: "",
                    valid: false,

                    name: "city",
                    label: "city",
                    icon: "world",
                    rules: "required|max:320",
                },
            ],

            country: "",
            comments: "",
            confirmTerms: false,
            triggerTerms: 0,
            termsValid: false,

            paymentMethod: "",
            deliveryType: "",

            errorOrder: null,
            loadingOrder: false,
            successOrder: false,
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.isStayInForm()) {
            next(false);
            window.location = to.path;
        } else {
            next();
        }
    },
    created() {
        window.addEventListener('beforeunload', this.beforeWindowUnload);


        this.token = this.$route.query.token;

        const galleryID = this.$route.params.gallery;
        try {
            uuidParse(galleryID);
            this.getGallery(galleryID, this.token);

        } catch (e) {
            this.loading = false;
            this.invalid = true;

        }

        if (this.getInfos.mandatoryFields.includes("telephone")) {
            this.contactFields[2].valid = false;
            this.contactFields[2].rules = "required|max:80";
        }
        this.paymentMethod = this.getInfos.paymentMethods[0];
        this.deliveryType = this.getDeliveryTypeOptions[0];
    },
    mounted() {
        this.introWatcher();

        let self = this;
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            self.isApp = false;
            self.promptInstallerEvent = e;
        });

        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (iOSSafari && !navigator.standalone) {
            this.isApp = false;
        }
    },
    computed: {
        getFormats() {
            let formats = [];

            let articles = this.gallery.articleListID;

            if (!articles) {
                return formats;
            }

            articles = articles.sort(this.articleFormatSortFunc);

            for (let i = 0, j = articles.length; i < j; i++) {
                let format = this.getArticleFormat(articles[i]);
                if (!formats.includes(format)) {
                    formats.push(format);
                }
            }

            return formats;
        },
        getDeliveryTypeOptions() {
            let options = [];
            options.push("pickup");

            if (this.paymentMethod === "shop") {
                return options;
            }

            if (this.getInfos.isShippingAllowed && this.paymentMethod === "invoice") {
                options.push("shipping");
            }

            return options;
        },
        isAddressRequired() {
            if (this.paymentMethod === "invoice") {
                return true;
            }

            return false;
        },
        isFeeDiscount() {
            let total = this.getTotalSamplesPrice;

            if (this.getInfos.feeDiscount != null && total >= this.getInfos.feeDiscount) {
                return true;
            }

            return false;
        },
        getTotalSamplesCount() {
            let total = 0;

            let pictures = this.selectedFiles;
            for (let i = 0, j = pictures.length; i < j; i++) {
                total += pictures[i].amount;
            }

            return total;
        },
        getTotalSamplesPrice() {
            let total = 0;

            let pictures = this.selectedFiles;
            for (let i = 0, j = pictures.length; i < j; i++) {
                total += this.getCostsByAmountAndArticleId(pictures[i]);
            }

            return total;
        },
        getFee() {
            let total = this.getTotalSamplesPrice;
            let fee = this.getInfos.fee;

            if (this.getInfos.feeDiscount != null && total >= this.getInfos.feeDiscount) {
                fee = 0.0;
            }

            return fee;
        },
        getTotal() {
            if (this.selectedFiles.length === 0) {
                return this.getNumberWithCurrency(0);
            }

            let total = this.getTotalSamplesPrice;
            let fee = this.getFee;
            let shipping = this.getShippingCosts;

            return this.getNumberWithCurrency(total + fee + shipping);
        },
        getShippingCosts() {
            let samples = this.getTotalSamplesCount;

            if (this.deliveryType !== "shipping") {
                return 0.0;
            }

            let shippingGraduatedPrices = this.getSortedGraduatedPrices(this.getInfos.shippingGraduatedPrices, true);
            for (let i = 0, j = shippingGraduatedPrices.length; i < j; i++) {
                if (samples >= shippingGraduatedPrices[i].amount) {
                    return shippingGraduatedPrices[i].price;
                }
            }

            return 0.0;
        },
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
    },
    methods: {
        actionSelectFolder(folder) {
            this.selectedFolder = folder;

            this.getFiles(this.gallery.id, this.token);

            UIkit.modal("#" + this.modalIdFolders).hide();
        },
        showFolderSelection() {
            if (!this.gallery.folders) {
                return;
            }

            UIkit.modal("#" + this.modalIdFolders).show();
        },
        actionReset() {
            this.selectedFiles = [];
            this.$forceUpdate();

            this.errorOrder = null;
            this.loadingOrder = false;
            this.successOrder = false;

            this.confirmTerms = false;
            this.termsValid = false;

            UIkit.modal("#" + this.modalIdOrderSuccess).hide();
        },
        handleAdd(file, articleID, amount, coordinates, cropped) {
            this.selectedFiles.push({
                path: file.path,
                name: file.name,
                amount: amount,

                width: file.width,
                height: file.height,

                articleID: articleID,

                coordinates: coordinates,
                cropped: cropped,
            });

            this.$forceUpdate();
        },
        isStayInForm() {
            let sessionInvalid = localStorage.getItem('session_invalid') !== null;

            return this.selectedFiles.length > 0 && !this.successOrder && !sessionInvalid;
        },
        beforeWindowUnload(e) {
            if (this.isStayInForm()) {
                // Cancel the event
                e.preventDefault();
                // Chrome requires returnValue to be set
                e.returnValue = '';
            }
        },
        getSortedGraduatedPrices(graduatedPrices, invert = false) {
            let graduatedPricesParsed = JSON.parse(JSON.stringify(graduatedPrices));

            function sortNodes(a, b) {
                if (a.amount < b.amount) return -1;
                if (a.amount > b.amount) return 1;
                return 0;
            }

            function sortNodesInvert(a, b) {
                if (a.amount > b.amount) return -1;
                if (a.amount < b.amount) return 1;
                return 0;
            }

            if (invert) {
                return graduatedPricesParsed.sort(sortNodesInvert);
            }

            return graduatedPricesParsed.sort(sortNodes);
        },
        setPaymentMethod(method) {
            if (this.successOrder || this.loadingOrder) {
                return;
            }

            this.paymentMethod = method;

            if (method === "shop") {
                this.deliveryType = "pickup";
            }

            if (method === "invoice") {
                this.country = this.getInfos.country;
            }
        },
        setDeliveryTypes(method) {
            if (this.successOrder || this.loadingOrder) {
                return;
            }

            this.deliveryType = method;
        },
        setValidContactFields(valid, fieldKey) {
            this.contactFields[fieldKey].valid = valid
        },
        setValidAddressFields(valid, fieldKey) {
            this.addressFields[fieldKey].valid = valid
        },
        setTermsValid(valid) {
            this.termsValid = valid;
        },
        manipulateAmount(key, manipulator) {
            this.selectedFiles[key].amount += manipulator;
            this.validateAmount(key);
        },
        validateAmount(key) {
            if (!this.selectedFiles[key].amount || this.selectedFiles[key].amount === 0) {
                this.actionRemovePicture(key);
                return;
            }

            if (this.selectedFiles[key].amount < 0) {
                this.selectedFiles[key].amount *= -1;
            }
        },
        getPictureFormatName(key) {
            let item = this.selectedFiles[key];

            if (item.coordinates) {
                if (item.coordinates.height > item.coordinates.width) {
                    return "vertical";
                }

                return "horizontal";
            }

            if (item.height > item.width) {
                return "vertical";
            }

            return "horizontal";
        },
        isVariableHeight(articleID) {
            let article = this.getArticleByID(articleID);

            return article.isHeightVariable
        },
        getFormatByArticleID(articleID, isShort = false) {
            let article = this.getArticleByID(articleID);
            return this.getPaperName(article.paper) + " " + this.getArticleFormat(article, isShort);
        },

        getPriceByArticleID(articleID) {
            let article = this.getArticleByID(articleID);

            let price = article.price;
            if (article.graduatedPrices) {
                let graduatedPrices = this.getSortedGraduatedPrices(article.graduatedPrices);
                let picturesAmount = this.getGroupedPicturesAmountByArticleID(articleID)

                for (let k = 0, l = graduatedPrices.length; k < l; k++) {
                    if (picturesAmount >= graduatedPrices[k].amount) {
                        price = graduatedPrices[k].price;
                    }
                }
            }

            return this.getNumberWithCurrency(price);
        },
        actionOrderView() {
            UIkit.modal("#" + this.modalIdOrderView).show();
        },
        validateContactDetails() {
            this.triggerContactFields++;

            if (!this.isContactDetailsValid) {
                return;
            }

            if (this.getInfos.mandatoryFields.includes('address')) {
                this.triggerAddressFields++;

                for (let i = 0, j = this.addressFields.length; i < j; i++) {
                    if (!this.addressFields[i].valid) {
                        return;
                    }
                }
            }
        },
        getCropperRatioByPictureKey(key) {
            let item = this.selectedFiles[key];
            if (!item) {
                return null;
            }

            let article = this.getArticleByID(item.articleID);

            if (article.isHeightVariable) {
                return null;
            }

            // hochformat
            if (item.height > item.width) {
                if (article.height > article.width) {
                    return article.width / article.height;
                }

                return article.height / article.width;
            }

            // querformat
            if (article.height > article.width) {
                return article.height / article.width;
            }

            return article.width / article.height;
        },
        handleCropperChange(itemKey) {
            const {coordinates, canvas} = this.$refs["cropper" + itemKey][0].getResult();

            if (!coordinates || (coordinates.height === 0 && coordinates.width === 0 && coordinates.left === 0 && coordinates.top === 0)) {
                return;
            }

            if (this.areCoordinatesEqual(this.coordinates, coordinates)) {
                return;
            }

            this.selectedFiles[itemKey].coordinates = coordinates;

            // this.getAndSetMeasurement(key)

            let self = this;
            canvas.toBlob(
                (blob) => {
                    this.selectedFiles[itemKey].cropped = self.getLocalFileUrl(blob);
                },
                "image/png",
                1.0,
            );
        },
        actionRemovePicture(key) {
            this.selectedFiles.splice(key, 1);
            this.$forceUpdate();
        },
        actionNext() {
            UIkit.tab('#cart-tab').show(1);
        },
        actionBack() {
            UIkit.tab('#cart-tab').show(0);
        },
        actionOrder() {
            this.triggerTerms++;
            this.triggerContactFields++;

            if (this.isAddressRequired || this.getInfos.mandatoryFields.includes('address')) {
                this.triggerAddressFields++;
            }


            let contactFields = this.contactFields;
            for (let i = 0, j = contactFields.length; i < j; i++) {
                if (!contactFields[i].valid) {
                    return false;
                }
            }

            if (this.getInfos.mandatoryFields.includes('address')) {
                this.triggerAddressFields++;

                for (let i = 0, j = this.addressFields.length; i < j; i++) {
                    if (!this.addressFields[i].valid) {
                        return;
                    }
                }
            }


            let street = null;
            let zip = null;
            let city = null;
            let country = null;
            if (this.isAddressRequired || this.getInfos.mandatoryFields.includes('address')) {
                this.triggerAddressFields++;

                for (let i = 0, j = this.addressFields.length; i < j; i++) {
                    if (!this.addressFields[i].valid) {
                        return;
                    }
                }

                street = this.addressFields[0].value;
                zip = this.addressFields[1].value;
                city = this.addressFields[2].value;
                country = this.country;
            }

            if (!this.termsValid) {
                return
            }

            this.loadingOrder = true;


            let platform = "browser";
            if (this.isApp) {
                platform = "app";
            }

            let parsedPictures = [];
            const selectedFiles = this.selectedFiles;
            for (let i = 0, j = selectedFiles.length; i < j; i++) {
                const selectedFile = selectedFiles[i];


                let path = selectedFile.path;
                if (path === "/") {
                    path = "";
                } else {
                    path += "/";
                }

                const [, , ratio] = this.calculateAspectRatioFit(selectedFile.width, selectedFile.height, 436, 638);
                const thumbnailCoordinates = selectedFile.coordinates;
                const pictureCoordinates = this.getOriginDimensions(thumbnailCoordinates, ratio);

                let width = pictureCoordinates.width;
                let height = pictureCoordinates.height;


                if (width > selectedFile.width) {
                    width = selectedFile.width;
                }
                if (height > selectedFile.height) {
                    height = selectedFile.height;
                }


                let top = parseInt(pictureCoordinates.top);
                if (top < 0) {
                    top = 0
                }

                let left = parseInt(pictureCoordinates.left);
                if (left < 0) {
                    left = 0
                }

                let thumbnailTop = parseInt(thumbnailCoordinates.top)
                if (thumbnailTop < 0) {
                    thumbnailTop = 0
                }

                let thumbnailLeft = parseInt(thumbnailCoordinates.left)
                if (thumbnailLeft < 0) {
                    thumbnailLeft = 0
                }


                parsedPictures.push(
                    {
                        path: path + selectedFile.name,
                        amount: selectedFile.amount,

                        articleID: selectedFile.articleID,

                        croppedWidth: parseInt(width),
                        croppedHeight: parseInt(height),
                        croppedTop: top,
                        croppedLeft: left,

                        thumbnailCroppedHeight: parseInt(thumbnailCoordinates.height),
                        thumbnailCroppedWidth: parseInt(thumbnailCoordinates.width),
                        thumbnailCroppedTop: thumbnailTop,
                        thumbnailCroppedLeft: thumbnailLeft,
                    }
                )
            }

            let http = axios.create();
            http.post(this.apiUrl + '/galleries/' + this.gallery.id + '/orders',
                {
                    pictures: parsedPictures,

                    name: this.contactFields[0].value,
                    email: this.contactFields[1].value,
                    telephone: this.contactFields[2].value,
                    street,
                    zip,
                    city,
                    country,
                    paymentMethod: this.paymentMethod,
                    deliveryType: this.deliveryType,
                    comments: this.comments,

                    language: this.$i18n.locale,
                    platform: platform,

                },
                {
                    headers: {
                        token: this.token,
                    },
                }
            ).then(() => {
                this.errorOrder = null;
                this.loadingOrder = false;
                this.successOrder = true;

                UIkit.modal("#" + this.modalIdOrderSuccess).show();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorOrder = e;
                this.loadingOrder = false;
                this.successOrder = false;

            });
        },
        getArticleByID(articleID) {
            let articles = this.gallery.articleListID;
            for (let i = 0, j = articles.length; i < j; i++) {
                if (articles[i].id === articleID) {
                    return articles[i];
                }
            }
        },
        getFirstArticleIDByFormat(format, paper) {
            if (!format) {
                if (!this.gallery.articleListID) {
                    return 0;
                }

                let favoriteArticle = this.getFavoriteArticle();
                if (favoriteArticle) {
                    return favoriteArticle.id;
                }

                return this.gallery.articleListID[0].id;
            }

            let articles = this.gallery.articleListID;

            articles = articles.sort((a, b) => Number(b.isFavorite) - Number(a.isFavorite));

            articles = articles.sort(this.articleFormatSortFunc)

            if (paper) {
                paper = this.getPaperName(paper);

                for (let i = 0, j = articles.length; i < j; i++) {
                    let parsedFormat = this.getArticleFormat(articles[i]);
                    if (format === parsedFormat && paper === this.getPaperName(articles[i].paper)) {
                        return articles[i].id;
                    }
                }
            }

            for (let i = 0, j = articles.length; i < j; i++) {
                let parsedFormat = this.getArticleFormat(articles[i]);
                if (format === parsedFormat) {
                    return articles[i].id;
                }
            }
        },
        getGroupedPicturesAmountByArticleID(articleID) {
            let amount = 0;

            let pictures = this.selectedFiles;
            for (let i = 0, j = pictures.length; i < j; i++) {
                if (pictures[i].articleID === articleID) {
                    amount += pictures[i].amount;
                }
            }

            return amount;
        },
        getCostsByAmountAndArticleId(item) {
            let articleID = item.articleID;
            let amount = item.amount;

            let article = this.getArticleByID(articleID);
            let price = article.price;

            if (article.graduatedPrices) {
                let graduatedPrices = this.getSortedGraduatedPrices(article.graduatedPrices);
                let picturesAmount = this.getGroupedPicturesAmountByArticleID(articleID)

                for (let k = 0, l = graduatedPrices.length; k < l; k++) {
                    if (picturesAmount >= graduatedPrices[k].amount) {
                        price = graduatedPrices[k].price;
                    }
                }
            }

            if (article.isHeightVariable) {
                let measurementValue = item.measurementValue;
                let measurementValueRounded = Math.round(measurementValue);

                price = price * measurementValueRounded

                price = Math.round(price * 10) / 10;

                if (article.minPrice && article.minPrice > price) {
                    price = article.minPrice
                }
            }

            return amount * price;
        },
        articleFormatSortFunc(a, b) {
            if (a.width < b.width) return -1;
            if (a.width > b.width) return 1;
            return 0;
        },

        introWatcher() {
            let self = this;
            this.introInterval = setInterval(function () {
                const introModal = UIkit.modal("#" + self.modalIdIntro);
                if (introModal) {
                    clearInterval(self.introInterval);
                    introModal.show();
                }
            }, 10);
        },
        selectFile(key) {
            const file = this.files[key];

            this.selectedFiles.push({
                path: file.path,
                name: file.name,
                amount: 1,
            });
        },
        deselectFile(key) {
            const selectedFiles = this.selectedFiles;
            const file = this.files[key];

            const fileId = file.path + file.name

            for (let i = 0, j = selectedFiles.length; i < j; i++) {
                let selectedFileId = selectedFiles[i].path + selectedFiles[i].name;

                if (fileId === selectedFileId) {
                    this.selectedFiles.splice(i, 1);
                    return;
                }
            }
        },
        toggleFileSelect(key) {
            if (this.isFileSelected(key)) {
                this.deselectFile(key);
                return;
            }

            this.selectFile(key);
        },
        isFileSelected(key) {
            const selectedFiles = this.selectedFiles;
            const file = this.files[key];

            const fileId = file.path + file.name

            for (let i = 0, j = selectedFiles.length; i < j; i++) {
                let selectedFileId = selectedFiles[i].path + selectedFiles[i].name;

                if (fileId === selectedFileId) {
                    return true;
                }
            }

            return false;
        },
        getGallery(id, token) {
            let http = axios.create();
            http.get(this.apiUrl + '/galleries/' + id, {
                    headers: {
                        token,
                    }
                }
            ).then(response => {
                this.gallery = response.data;

                this.getFiles(id, token)

            }).catch(e => {
                this.invalid = true;

                this.checkGenericError(e);
                this.error = e;
                this.loading = false;
                this.success = false;

            })
        },
        getFirstFormat() {
            let formats = this.getFormats;

            let favoriteArticle = this.getFavoriteArticle();
            if (favoriteArticle) {
                return this.getArticleFormat(favoriteArticle);
            }

            return formats[0];
        },
        getArticleFormat(article) {
            if (article.name) {
                return article.name;
            }

            if (article.isHeightVariable) {
                return this.roundWidth(article.width) + ' ' + this.$t(this.getInfos.measurementUnit) + ' x ' + this.$t('pictureLength');
            }

            return this.roundWidth(article.width) + ' x ' + this.roundHeight(article.height) + ' ' + this.$t(this.getInfos.measurementUnit);
        },
        getFavoriteArticle() {
            let articles = this.gallery.articleListID;

            if (articles) {
                for (let i = 0, j = articles.length; i < j; i++) {
                    if (articles[i].isFavorite) {
                        return articles[i];
                    }
                }
            }

            return null;
        },
        getFiles(id, token) {
            let folder = "";
            if (this.selectedFolder) {
                folder = "/folders/" + this.selectedFolder.name;
            }

            this.filesLoading = true;

            let http = axios.create();
            http.get(this.apiUrl + '/galleries/' + id + folder + '/files', {
                    headers: {
                        token,
                    }
                }
            ).then(response => {
                this.files = response.data;

                this.error = null;
                this.loading = false;
                this.success = true;
                this.filesLoading = false;

                this.$forceUpdate();

            }).catch(e => {
                this.invalid = true;

                this.checkGenericError(e);
                this.error = e;
                this.loading = false;
                this.success = false;
                this.filesLoading = false;

            })
        }
    }
}

</script>


<style lang="scss" scoped>

.generated-title {
    text-transform: uppercase;
    font-size: 64px;
    font-weight: 500;
    color: #666;
}

.gallery-title {
    font-size: 24px;
}


.image-item-struct {
    max-height: 150px;
    margin: auto;
}

.image-item {
    height: 100%;
    margin: auto;
}

.thumbnail {
    max-width: 256px;
    max-height: 256px;

    width: 100%;
    height: 100%;
}

.vat {
    font-size: 14px;
    margin-top: 15px;
}


.uk-accordion-title {
    font-size: 16px !important;
}

.sticky-accordion-content {
    margin-right: 32.391px;
}

.section-title {
    font-size: 18px;
    font-weight: 500;
    color: $site-background;
    margin-bottom: 10px;
}


.badge {
    font-size: 16px;
    padding: 10px 15px;
    margin-top: -5px;
    margin-right: 5px;
}

.logo {
    height: 42px;
}

.logo-struct {
    height: 42px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.empty-logo-struct {
    padding-top: 60px;
}

.galleries-struct {
    background-color: $site-color;
    // min-height: 80vh;
}


.galleries-struct2 {
    background-color: $site-color;
}

.infos-name {
    margin-right: 5px;
}

.iname {
    font-size: 16px;
    color: $site-background !important;
}

.dark {
    color: $site-background !important;
}

.gallery-name {
    font-size: 18px;
    font-weight: 500;
    color: $site-background !important;
}

.total-struct {
    padding-top: 15px !important;
    padding-bottom: 15px !important;

    position: sticky;
    top: 0;
    z-index: 1000;
    background: #fff !important;
    // border-bottom: 1px solid $border-color !important;

    backdrop-filter: saturate(180%) blur(20px);
    //background-color: rgba(29, 29, 31, 0.1) !important;
    background-color: rgba(243, 246, 249, .1) !important;
}

.picture-name {
    font-size: 16px;
    margin-top: 10px;
}

.gallery-mobile {
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(243, 246, 249, 0.1) !important;
}

</style>
